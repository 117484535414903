<template>
    <div
        v-if="showWidget"
        :id="'widget-' + config.widgetId"
        :class="[
            'c-chart',
            'c-box',
            chartClass ? chartClass : '',
            'widget',
            'draggable-widget',
            loading ? 'is-loading' : '',
        ]"
    >
        <div
            v-show="editMode && (showExpandableBox || isExpandableLeft || isExpandableRight)"
            class="widget-expand-menu clearfix"
        >
            <a v-show="isExpandableLeft" @click="expandWidget(-1)"
                ><span class="material-icons">arrow_back</span></a
            >
            <a v-show="isExpandableRight" class="expand_right" @click="expandWidget(1)"
                ><span class="material-icons">arrow_forward</span></a
            >
        </div>
        <div class="c-chart__header c-drawer">
            <h2 class="c-chart__heading">{{ config.widgetTitle }}</h2>
            <div v-if="widget != null" class="c-chart__actions">
                <el-tooltip
                    :openDelay="500"
                    :content="$t('SPLIT_BY')"
                    effect="dark"
                    placement="bottom"
                >
                    <button
                        v-if="canChangeSplitBy"
                        :class="[
                            'c-btn',
                            'c-btn--clean',
                            'c-btn--gray',
                            'widget-split-by-btn-' + config.widgetId,
                            showSplitBy ? 'is-active' : '',
                        ]"
                        @click="showSplitBy = !showSplitBy"
                    >
                        <i :class="['material-icons', 'widget-split-by-btn-' + config.widgetId]"
                            >compare_arrows</i
                        >
                    </button>
                </el-tooltip>
                <el-tooltip
                    :openDelay="500"
                    :content="$t('FILTER')"
                    effect="dark"
                    placement="bottom"
                >
                    <button
                        :class="[
                            'c-btn',
                            'c-btn--clean',
                            'c-btn--gray',
                            'widget-filter-btn-' + config.widgetId,
                            showFilter ? 'is-active' : '',
                        ]"
                        @click="showFilter = !showFilter"
                    >
                        <i :class="['material-icons', 'widget-filter-btn-' + config.widgetId]"
                            >filter_list</i
                        >
                    </button>
                </el-tooltip>
                <el-tooltip
                    v-if="
                        !errorMessage &&
                        !chartErrorMessage &&
                        chartOptions != null &&
                        showGraph &&
                        widgetData.widgetType !== 'SINGLE_VALUE' &&
                        widgetData.widgetType !== 'TABLE'
                    "
                    :openDelay="500"
                    :content="$t('EXPORT')"
                    effect="dark"
                    placement="bottom"
                >
                    <div class="c-dropdown">
                        <button
                            v-click-outside="closeDropdown"
                            class="c-btn c-btn--clean c-btn--gray"
                            @click="toggleDropdown"
                        >
                            <i class="material-icons">cloud_download</i>
                        </button>
                        <div
                            class="c-dropdown__container c-dropdown__container--right c-dropdown__container--arrow widget-export-container"
                        >
                            <span class="c-dropdown__arrow"><span></span><span></span></span>
                            <ul class="c-dropdown__list o-list-plain">
                                <li>
                                    <button
                                        class="c-btn c-btn--block c-btn--icon"
                                        @click="printChart"
                                    >
                                        {{ $t("DASHBOARD_APP_PRINT_CHART") }}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        class="c-btn c-btn--block c-btn--icon"
                                        @click="exportChart()"
                                    >
                                        {{ $t("DASHBOARD_APP_EXPORT_CHART_PNG") }}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        class="c-btn c-btn--block c-btn--icon"
                                        @click="exportChart('image/jpeg')"
                                    >
                                        {{ $t("DASHBOARD_APP_EXPORT_CHART_JPEG") }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-tooltip>
                <div class="c-dropdown">
                    <button
                        v-click-outside="closeDropdown"
                        class="c-btn c-btn--clean c-btn--gray"
                        @click="toggleDropdown"
                    >
                        <i class="material-icons">more_horiz</i>
                    </button>
                    <div
                        class="c-dropdown__container c-dropdown__container--right c-dropdown__container--arrow"
                    >
                        <span class="c-dropdown__arrow"><span></span><span></span></span>
                        <ul class="c-dropdown__list o-list-plain">
                            <li
                                v-if="
                                    (role === 'ROLE_ADMIN' ||
                                        role === 'ROLE_SUPERADMIN' ||
                                        editable) &&
                                    editMode
                                "
                            >
                                <button class="c-btn c-btn--block c-btn--icon" @click="editWidget">
                                    <i class="material-icons">edit</i>{{ $t("EDIT") }}
                                </button>
                            </li>
                            <li v-if="role === 'ROLE_SUPERADMIN'">
                                <button
                                    class="c-btn c-btn--block c-btn--icon"
                                    @click="editInLibrary"
                                >
                                    <i class="material-icons">edit_note</i>
                                    EDIT WIDGET TEMPLATE
                                </button>
                            </li>
                            <li>
                                <button
                                    class="c-btn c-btn--block c-btn--icon"
                                    @click="reloadWidget"
                                >
                                    <i class="material-icons">refresh</i>{{ $t("RELOAD") }}
                                </button>
                            </li>
                            <li
                                v-if="
                                    impersonation ||
                                    role === 'ROLE_ADMIN' ||
                                    role === 'ROLE_SUPERADMIN' ||
                                    (!errorMessage &&
                                        widgetData != null &&
                                        (widgetData.widgetType === 'TABLE' ||
                                            (!chartErrorMessage && chartOptions != null)))
                                "
                            >
                                <button
                                    class="c-btn c-btn--block c-btn--icon"
                                    @click="openFullScreen"
                                >
                                    <i class="material-icons">fullscreen</i>{{ $t("FULLSCREEN") }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <el-tooltip
                    v-if="editMode"
                    :openDelay="500"
                    :content="$t('REMOVE')"
                    effect="dark"
                    placement="bottom"
                >
                    <button
                        v-if="editMode && canBeRemoved"
                        class="c-btn c-btn--clean c-btn--gray"
                        @click="removeWidget"
                    >
                        <i class="material-icons">close</i>
                    </button>
                </el-tooltip>
            </div>
            <div v-if="showGraphTableSwitcher" class="c-toggle">
                <input
                    :id="'widget-chart-btn-' + config.widgetId"
                    v-model="showGraph"
                    :value="true"
                    class="c-toggle__item c-toggle__item--left"
                    type="radio"
                />
                <label :for="'widget-chart-btn-' + config.widgetId" @click="showGraph = true"
                    ><i class="material-icons">bar_chart</i></label
                >
                <input
                    :id="'widget-table-btn-' + config.widgetId"
                    v-model="showGraph"
                    :value="false"
                    class="c-toggle__item c-toggle__item--right"
                    type="radio"
                />
                <label :for="'widget-table-btn-' + config.widgetId" @click="showGraph = false"
                    ><i class="material-icons">table_chart</i></label
                >
            </div>
        </div>
        <split-by
            v-if="widget != null"
            v-show="canChangeSplitBy && showSplitBy"
            ref="splitBy"
            v-click-outside="outsideSplitByClick"
            :visible="showSplitBy"
            :widget="widget"
            @close="showSplitBy = false"
            @reloadWidget="reloadWidget"
        ></split-by>
        <filter-component
            v-if="widget != null"
            v-show="showFilter"
            ref="filter"
            v-click-outside="outsideFilterClick"
            :applied-filters="appliedFilters"
            :visible="showFilter"
            :widget-id="config.widgetId"
            @reload="reloadWidget"
            @applyFilter="applyFilter"
        ></filter-component>
        <div class="c-chart__content">
            <applied-filters
                v-show="!loading && appliedFilters.length > 0"
                :applied-filters="appliedFilters"
                @reload="reloadWidget"
            ></applied-filters>
            <applied-split-bys
                v-show="!loading && widget != null && widget.splitBys.length > 0"
                :widget="widget"
                @reloadWidget="reloadWidget"
            ></applied-split-bys>
            <div class="c-chart__media">
                <widget-view
                    ref="widgetView"
                    :chart-error-message="chartErrorMessage"
                    :chart-options="chartOptions"
                    :error-message="errorMessage"
                    :preview="false"
                    :show-graph="showGraph"
                    :table-data="tableData"
                    :widget-data="widgetData"
                    :x-axis-name="xAxisName"
                ></widget-view>
            </div>
        </div>
        <div v-show="loading" class="c-loader"></div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { EventBus } from "@/helpers/evaluation/event-bus";
import util from "@/helpers/evaluation/util";
import { chartService, splitByService, widgetService } from "@/services/Evaluation";
import WidgetView from "./WidgetView.vue";
import AppliedFilters from "./AppliedFilters.vue";
import AppliedSplitBys from "./AppliedSplitBys.vue";
import SplitBy from "./action/SplitBy.vue";
import FilterComponent from "./action/Filter.vue";

export default {
    name: "Widget",
    mixins: [util, validationMixin],
    props: [
        "config",
        "editable",
        "editMode",
        "canBeRemoved",
        "isExpandableLeft",
        "isExpandableRight",
        "showExpandableBox",
        "widgetColClass",
        "chartClass",
    ],
    components: {
        WidgetView,
        AppliedFilters,
        AppliedSplitBys,
        SplitBy,
        FilterComponent,
    },
    data() {
        return {
            loading: false,

            widget: null,
            dataSet: null,
            showWidget: true,

            showSplitBy: false,
            showFilter: false,
            showGraph: true,
            appliedFilters: [],

            hasData: false,
            widgetData: null,
            chartOptions: null,
            errorMessage: null,
            chartErrorMessage: null,

            tableData: null,
            xAxisName: null,
        };
    },
    computed: {
        role() {
            return "ROLE_SUPERADMIN";
        },
        impersonation() {
            return false;
            // return this.$store.state.sharedStore.userData.impersonation;
        },
        language() {
            return this.$i18n.locale;
        },
        showGraphTableSwitcher() {
            return (
                this.widgetData != null &&
                this.widgetData.widgetType != null &&
                this.widgetData.widgetType !== "TABLE" &&
                this.widgetData.widgetType !== "SINGLE_VALUE" &&
                this.widgetData.widgetType !== "GAUGE" &&
                this.widgetData.widgetType !== "MOOD_MONITOR_GAUGE"
            );
        },
        canChangeSplitBy() {
            return (
                this.widget != null &&
                this.widget.widgetTemplate.splitByAllowed &&
                (this.widget.splitBys.length === 0 ||
                    !this.widget.splitBys[0].irremovable ||
                    this.role === "ROLE_ADMIN" ||
                    this.role === "ROLE_SUPERADMIN")
            );
        },
    },
    created() {
        EventBus.$on("reloadWidget", this.reloadWidgetHandler);

        this.getWidget(false);
    },
    beforeDestroy() {
        EventBus.$off("reloadWidget", this.reloadWidgetHandler);
    },
    methods: {
        getWidget(reload) {
            this.loading = true;

            widgetService
                .getWidget(this.config.widgetId, reload)
                .then((widget) => {
                    this.widget = widget;
                    this.appliedFilters = widget.appliedFilters;
                    this.getWidgetDataSet(reload);
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status === 404) {
                        this.showWidget = false;
                        this.$emit("removed", this.config.widgetId);
                    }
                });
        },
        getWidgetDataSet(reload) {
            this.loading = true;
            widgetService.getWidgetDataSet(this.config.widgetId, null, reload).then((result) => {
                this.showWidget = result.exists;
                this.errorMessage = result.errorMessage;
                this.dataSet = result.dataSet;
                this.hasData = result.hasData;
                this.loading = false;
                if (this.hasData) {
                    this.getWidgetData();
                    if (!this.chartErrorMessage) {
                        splitByService.storeProperties();
                    }
                }
            });
        },
        getWidgetData() {
            const widgetData = chartService.getWidgetData(
                this.$i18n,
                this.widget,
                this.dataSet,
                false,
                false,
                this.widgetColClass
            );
            this.chartErrorMessage = widgetData.error;
            this.widgetData = widgetData.widget;
            this.chartOptions = widgetData.chartConfig;
            this.tableData = widgetData.tableData;
            this.xAxisName = widgetData.widget.xAxisName;
        },
        applyFilter() {
            if (this.showFilter) this.showFilter = false;
        },
        reloadWidget() {
            this.getWidget(true);
        },
        reloadWidgetHandler(widgetId) {
            if (!widgetId || this.config.widgetId === widgetId) {
                this.reloadWidget();
            }
        },
        removeWidget() {
            if (!this.canBeRemoved) {
                return;
            }

            this.$confirm(
                this.translate("DASHBOARD_APP_REMOVE_WIDGET_MSG", {
                    itemTitle: this.config.widgetTitle,
                }),
                this.translate("DASHBOARD_APP_REMOVE_WIDGET_TITLE"),
                {
                    confirmButtonText: this.translate("REMOVE"),
                    cancelButtonText: this.translate("CANCEL"),
                    type: "warning",
                }
            ).then(() => {
                widgetService
                    .deleteWidget(this.config.widgetId)
                    .then(() => {
                        this.$emit("removed", this.config.widgetId);
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.$emit("removed", this.config.widgetId);
                        }
                    });
            });
        },
        openFullScreen() {
            EventBus.$emit("openWidgetPopup", this.widget);
        },
        expandWidget(direction) {
            this.$emit("expand", direction);
        },
        editWidget() {
            EventBus.$emit("openWidgetEdit", {
                widget: this.widget,
                config: this.config,
            });
        },
        editInLibrary() {
            widgetService
                .getWidgetTemplate(this.widget.widgetTemplate.id)
                .then((response) => {
                    this.$storage.setItem("widgetToEdit", response.data);
                    this.$storage.setItem("fromWidgetLibrary", false);
                    this.$router.push(`/widget-templates/${this.widget.widgetTemplate.id}`);
                    EventBus.$emit("routeChanged");
                })
                .catch(() => {
                    this.displayMessage(this.translate("EDIT_WIDGET_TEMPLATE_ERROR"), "error");
                });
        },
        getChart() {
            if (
                this.$refs.widgetView &&
                this.$refs.widgetView.$refs.widgetChart &&
                this.$refs.widgetView.$refs.widgetChart.$refs.chart &&
                this.$refs.widgetView.$refs.widgetChart.$refs.chart.chart
            ) {
                return this.$refs.widgetView.$refs.widgetChart.$refs.chart.chart;
            }
            return null;
        },
        printChart() {
            const chart = this.getChart();
            if (chart != null) {
                chart.print();
            }
        },
        exportChart(exportType) {
            const chart = this.getChart();
            if (chart != null) {
                if (exportType) {
                    chart.exportChartLocal({ type: exportType });
                } else {
                    chart.exportChartLocal();
                }
            }
        },
        outsideSplitByClick(event) {
            if (
                !event.target.classList.contains(`widget-split-by-btn-${this.config.widgetId}`) &&
                !this.$refs.splitBy.$refs.filterValueDropdown.classList.contains("is-active")
            ) {
                this.showSplitBy = false;
            }
        },
        outsideFilterClick(event) {
            if (
                !event.target.classList.contains(`widget-filter-btn-${this.config.widgetId}`) &&
                !this.$refs.filter.$refs.valueFilter.$refs.filterValueDropdown.classList.contains(
                    "is-active"
                )
            ) {
                this.showFilter = false;
            }
        },
    },
    watch: {
        language() {
            if (!this.loading && this.hasData) {
                this.getWidgetData();
            }
        },
    },
};
</script>
