<template>
    <div v-if="widget != null" class="c-filter-list">
        <i class="material-icons">compare_arrows</i>
        <ul class="c-filter-list__list o-list-plain">
            <li v-for="(splitBy, i) in splitBysSorted" :key="i" class="c-filter-list__item">
                <el-tooltip
                    :openDelay="500"
                    :content="getSplitByTooltip(splitBy)"
                    effect="dark"
                    placement="bottom"
                    popper-class="filter-tooltip"
                >
                    <span :class="['c-tag', splitBy.irremovable ? 'c-tag--irremovable' : '']">
                        {{ getSplitByDisplayText(splitBy) }}
                        <button v-if="splitBy.irremovable" class="irremovable-btn">
                            <i class="material-icons">lock</i>
                        </button>
                        <button
                            v-if="canRemoveSplitBy(splitBy)"
                            @click.stop="removeSplitBy(splitBy)"
                        >
                            <i class="material-icons">close</i>
                        </button>
                    </span>
                </el-tooltip>
            </li>
        </ul>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { splitByService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "AppliedSplitBys",
    mixins: [util, validationMixin],
    props: ["widget"],
    computed: {
        role() {
            return "ROLE_SUPERADMIN";
        },
        splitBysSorted() {
            if (this.widget && this.widget.splitBys != null && this.widget.splitBys.length > 0) {
                const splitBys = this.copyObject(this.widget.splitBys);
                splitBys[0].splitByValues.sort(this.objectSortFunc("value"));
                return splitBys;
            }
            return [];
        },
    },
    methods: {
        getSplitByTooltip(splitBy) {
            let title = "";
            $.each(splitBy.splitByValues, (index, splitByValue) => {
                title += `${splitByValue.value}, `;
            });
            title = title.replace(/,\s*$/, "");
            return title;
        },
        getSplitByDisplayText(splitBy) {
            return `${this.translate(splitBy.filterTemplate.name)} = ${
                splitBy.splitByValues.length > 0 ? splitBy.splitByValues[0].value : ""
            }${splitBy.splitByValues.length > 1 ? "..." : ""}`;
        },
        canRemoveSplitBy(splitBy) {
            return (
                !splitBy.irremovable ||
                this.role === "ROLE_ADMIN" ||
                this.role === "ROLE_SUPERADMIN"
            );
        },
        removeSplitBy(splitBy) {
            splitByService.delete(this.widget.id, splitBy.id).then(() => {
                this.$emit("reloadWidget");
            });
        },
    },
};
</script>
